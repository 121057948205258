import React, { useState, useCallback } from 'react';
import {
  MUSICALS,
  SHORT_FILMS,
  SITE_TITLE_APPEND,
  THE_JOURNEY,
  WEB_SERIES,
} from '../constants';
import Seo from '../components/seo';
import Layout, { PageContents } from '../components/layout';
import Header from '../components/header';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from 'react-bootstrap';

import { StaticImage } from 'gatsby-plugin-image';

// markup
const IndexPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  const [limits, setLimits] = useState({});

  // our works limit to 3 images
  const workLimit = 3;

  // to show all images
  const removeLimit = useCallback((sectionName) => {
    setLimits((prevValues) => ({ ...prevValues, [sectionName]: 'all' }));
  }, []);

  const renderSection = useCallback(
    (sectionDetails, sectionName = '') => {
      const details =
        limits[sectionName] !== 'all'
          ? sectionDetails.slice(0, workLimit)
          : sectionDetails;

      let renderHtml = details.map((item, index) => {
        const [title, value] = Object.entries(item)[0];
        const link = value?.[0] || '#';
        const thumbnail = value?.[1] || null;
        return (
          <div className="col-auto" key={`title-${index}`}>
            <div className="card mb-4" style={{ width: '18rem' }}>
              <a href={link} target="_blank" rel="noreferrer">
                {thumbnail && (
                  <img src={thumbnail} className="card-img-top" alt={title} />
                )}
                <div className="card-body">
                  <p className="small text-center card-text">{title}</p>
                </div>
              </a>
            </div>
          </div>
        );
      });

      // append the View more button
      if (limits[sectionName] !== 'all' && sectionDetails.length > workLimit) {
        renderHtml = renderHtml.concat([
          <div className="col-auto" key={`view-more-${sectionName}`}>
            <button
              className="mb-4 btn btn-sm btn-secondary"
              onClick={() => {
                removeLimit(sectionName);
              }}
            >
              View more
            </button>
          </div>,
        ]);
      }

      return renderHtml;
    },
    [limits]
  );
  return (
    <Layout>
      <main>
        <Seo title={`Home ${SITE_TITLE_APPEND}`} />
        <Header />
        <Container>
          <Slider {...settings}>
          <div>
              <StaticImage
                src="../images/love-you-team.jpg"
                alt="Love You Team"
              />
            </div>
            <div>
              <StaticImage
                src="../images/love-you-lyrical-video.jpg"
                alt="Love You Lyrical Video"
              />
            </div>
            <div>
              <StaticImage
                src="../images/hiring-june-2024.jpg"
                alt="Hiring Digital Marketing Manager"
              />
            </div>
            <div>
              <StaticImage
                src="../images/some-of-us-2.jpg"
                alt="Some of us team"
              />
            </div>
            <div>
              <StaticImage
                src="../images/some-of-us-1.jpg"
                alt="Some of us team"
              />
            </div>
            {/*<div>
              <StaticImage
                src="../images/sooraj.jpg"
                alt="Sooraj-Some of us team"
              />
            </div>
            <div>
              <StaticImage
                src="../images/some-of-us-team.jpg"
                alt="Some of us team"
              />
            </div>
            <div>
              <StaticImage
                src="../images/miss-you-team.jpg"
                alt="Some of us team"
              />
            </div>
             <div>
              <StaticImage
                src="../images/miss-you-out.jpg"
                alt="Some of us - Miss you video out"
              />
            </div>
            <div>
              <StaticImage src="../images/some-of-us.jpg" alt="Some of us" />
            </div>
            <div>
              <StaticImage src="../images/gbm.jpg" alt="Dravida GBM" />
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=dO3H1oj6Le8&ab_channel=DravidaEntertainments"
              >
                <StaticImage
                  src="../images/dhwayam-short-film.jpg"
                  alt="Dhwayam Short Film"
                />
              </a>
            </div> */}
            {/* <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=CSL-Ts6UfZY&ab_channel=DravidaEntertainments"
              >
                <StaticImage
                  src="../images/dhwayam.jpg"
                  alt="Dhwayam Short Film Teaser"
                />
              </a>
            </div> */}
            {/* <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=T6B2kioHTa0&ab_channel=DravidaEntertainments"
              >
                <StaticImage
                  src="../images/short-film-10.jpg"
                  alt="Short Film 10 Title Launch Video"
                />
              </a>
            </div> */}
            {/* <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=sFsREEIxBrI&ab_channel=DravidaEntertainments"
              >
                <StaticImage
                  src="../images/pachil-prologue.jpg"
                  alt="Pachil Prologue"
                />
              </a>
            </div> */}
            {/* <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=Rt7e8TOVEt0&ab_channel=DravidaEntertainments"
              >
                <StaticImage
                  src="../images/aalunne-pulijanmam.jpg"
                  alt="Aallunne song - Pulijanmam"
                />
              </a>
            </div> */}
            {/* <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=7jvs9uWL_J8&ab_channel=DravidaEntertainments"
              >
                <StaticImage
                  src="../images/chemmanam-pulijanmam.jpg"
                  alt="Chemmanam song - Pulijanmam"
                />
              </a>
            </div> */}
            {/*<div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=VzBF1ImQgH0&ab_channel=DravidaEntertainments"
              >
                <StaticImage
                  src="../images/condom-theory-views.jpg"
                  alt="Condom theory short film"
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=LxCGGg4EMvs&ab_channel=DravidaEntertainments"
              >
                <StaticImage
                  src="../images/condom-theory-teaser.jpg"
                  alt="Condom theory teaser"
                />
              </a>{' '}
            </div>
             <div>
              <a
                target="_blank"
                 rel="noreferrer"
                href="https://www.youtube.com/watch?v=A5lfObDCJm4&ab_channel=DravidaEntertainments"
              >
                <StaticImage
                  src="../images/condom-title-launch.jpg"
                  alt="Short Film #9 Title Launch Video"
                />
              </a>
            </div> */}

            {/*  <div>
              <StaticImage
                src="../images/pachil-5.jpg"
                alt="Paachil web series (Part 5)"
              />
            </div> */}
            {/*  <div>
              <StaticImage
                src="../images/lyrical-video-paachil.jpg"
                alt="Lyrical Video | Paachil"
              />
            </div>
            <div>
              <StaticImage
                src="../images/pachil-4.jpg"
                alt="Paachil web series (Part 4)"
              />
            </div>
            <div>
              <StaticImage
                src="../images/pachil-3.jpg"
                alt="Paachil web series (Part 3)"
              />
            </div>
            <div>
              <StaticImage
                src="../images/carousel-1.jpg"
                alt="Paachil web series"
              />
            </div> */}
          </Slider>
        </Container>
        <PageContents>
          <div className="mt-4 custom-text">
            <p>
              A decade of efforts to create a parallel production etiquette, in
              different streamlines - Originals, Distributions, Music, evolving
              with changes, inside and outside, growing stronger at every turn.
              We are a new team with a new direction. New ideas, new
              possibilities. We are here to sustain and make a mark.
            </p>
          </div>
        </PageContents>
        <PageContents title="The Journey">
          <div className="row justify-content-center  align-items-center">
            {renderSection(THE_JOURNEY, 'THE_JOURNEY')}
          </div>
        </PageContents>
        <PageContents title="Web Series">
          <div className="row justify-content-center  align-items-center">
            {renderSection(WEB_SERIES, 'WEB_SERIES')}
          </div>
        </PageContents>
        <PageContents title="Musicals">
          <div className="row justify-content-center align-items-center">
            {renderSection(MUSICALS, 'MUSICALS')}
          </div>
        </PageContents>
        <PageContents title="Short Films">
          <div className="row justify-content-center  align-items-center">
            {renderSection(SHORT_FILMS, 'SHORT_FILMS')}
          </div>
        </PageContents>
      </main>
    </Layout>
  );
};

export default IndexPage;
